<template>
    <v-sheet style="width:95%; height: 100%;">
    <v-row class="mt-4 ">
        <h1 class="ml-3 heading_styles">Contact Us</h1>
    </v-row>
    <v-row>
        <v-col cols="12" md="6">
            <v-text-field variant="outlined" label="First Name" placeholder="Enter Your First Name" ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
            <v-text-field variant="outlined" label="Last Name" placeholder="Enter Your Last Name" ></v-text-field>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="6">
            <v-text-field variant="outlined" label="Email" placeholder="Enter Your Email" ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
            <v-text-field variant="outlined" label="Phone Number" placeholder="Enter Your Phone Number" ></v-text-field>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-text-field variant="outlined" label="Subject" placeholder="Enter Subject Of Email" ></v-text-field>
        </v-col>       
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-textarea variant="outlined" label="Message" placeholder="Please Explain our Thoughts" >

            </v-textarea>
        </v-col>       
    </v-row>
    <v-row>
        <v-col cols="12" class="text-end">
            <v-btn variant="outlined"  color="red">
                submit
            </v-btn>
        </v-col>       
    </v-row>
</v-sheet>
</template>
<script>
export default
{
    data:()=>({

    })
}
</script>
<style scoped>

</style>