<template >
    <div class="d-flex jutstify-center align-center  height_control_not_found " style="height: 100dvh;">
        <v-row class="text-center">
            <v-col cols="5 "></v-col>
            <v-col cols="2 " class="text-md-h4 text-blue-grey-lighten-3">404 | Not Found</v-col>
            <v-col cols="5 "></v-col>
        </v-row>
    </div>
    
    
</template>

<script>
    export default {
        
    }
</script>

<style scoped >
.height_control_not_found{
    height:100%;
}
</style>