<template>
<v-sheet style="width:95%; height: 100%;">
    <v-row class="mt-4 ">
        <h1 class="ml-3 heading_styles">My Farms</h1>
    </v-row>
    <v-row class="d-flex justify-space-between">
        <v-col cols="12" sm="6">
            <v-select :items="all_user_business" item-title="name" item-value="id" label=" Business Name" class="text-high-emphasis " variant="outlined" density="compact" v-model="search_business_farm" clearable></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="text-end ">
            <v-btn variant="outlined" class="my-auto" prepend-icon="mdi-filter" :width="$vuetify.display.xs?'100%':''" size="large" @click="GetBusinessDetails()">
                Apply Filter
            </v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-table height="90dvh" fixed-header>
                <thead>
                    <tr style="background-color: #102536; color: #b0b4bb;">
                        <td>FARM NAME</td>
                        <td>BUSINESS TYPE</td>
                        <td>MANAGER</td>
                        <td>EMAIL</td>
                        <td>WEBSITE</td>
                        <td>STATUS</td>
                        <td>NO OF ORDERS</td>
                        <td>NO OF PRODUCTS</td>
                        <td>ACTIONS</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in user_business" :key="index">
                        <td> {{ item.name }}</td>
                        <td> {{ item.business_type}}</td>
                        <td> {{ item.full_name }} </td>
                        <td> {{ item.email }}</td>
                        <td> {{ item.website }} </td>
                        <td>
                            <!-- <template v-if="item.editable"> -->
                            <!-- <td> -->
                            <!-- <v-select :items="status" item-title="status" item-value="value" label="Status" class="text-high-emphasis  " variant="outlined" density="compact" v-model="business.status"></v-select> -->
                            <!-- </td> -->
                            <!-- </template> -->
                            <!-- <template v-else> -->
                            <p v-if="item.status" class="text-green"> Active</p>
                            <p v-else class="text-red">In Active</p>
                            <!-- </template> -->
                        </td>
                        <td class="text-center ">
                            <v-btn flat class="hover-color" @click="get_business_specific_orders(item.id, 'my_orders')"> {{ item.number_of_orders }} </v-btn>
                        </td>
                        <td class="text-center">
                            <v-btn flat class="hover-color" @click="get_business_specific_orders(item.id,'my_products')"> {{ item.number_of_products }} </v-btn>
                        </td>
                        <td>
                            <!-- <template v-if="item.editable">
                                <v-btn icon="mdi-content-save" variant="text"></v-btn>
                                <v-btn icon="mdi-close" variant="text" color="red" @click="item.editable=false"></v-btn>
                            </template> -->
                            <!-- <template v-else> -->

                            <v-btn icon="mdi-table-edit" color="green" variant="text" @click="EditBusinessDetails(item)"> </v-btn>
                            <v-btn icon="mdi-delete-outline" color="error" variant="text" disabled @click="business_dialog = true"
                            > </v-btn>

                            <!-- </template> -->
                        </td>

                    </tr>
                    <tr v-if="response_message" class="text-center text-red text-h6">
                           <td colspan="10">{{ response_message }}</td>
                        </tr>
                </tbody>

            </v-table>
        </v-col>
    </v-row>
</v-sheet>
<v-dialog v-model="business_dialog" transition="dialog-top-transition">
    <v-container class="bgcard">
        <v-card class="mx-auto mt-16 rounded-xl elevation-7" color="white" max-width="600">
            <v-card-title class="text-center text-h5 mt-4 font-weight-bold"> ADD NEW BUSINESS </v-card-title>
            <v-card-text>

                <v-row class="px-2 mt-2">
                    <v-col cols="12" md="6">
                        <v-text-field label="Business Name" placeholder="Enter your Business Name" class="text-high-emphasis " variant="outlined" density="compact" required readonly v-model="update_business_details.name"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field label="Email Address" placeholder="Enter Your Business Email" class="text-high-emphasis " variant="outlined" density="compact" required readonly v-model="update_business_details.email"></v-text-field>
                    </v-col>
                </v-row>
                 <v-row class="px-2 mt-2">
                    <v-col cols="12" md="6">
                        <v-text-field label="Contact Number" placeholder="Enter your Contact Number" class="text-high-emphasis " variant="outlined" density="compact" required v-model="update_business_details.contact_number"></v-text-field>
                        <small v-if="v$.update_business_details.contact_number.$error" class="text-red">
                Contact Number should be <span class="text-green">03XXXXXXXXX</span>
            </small>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field label="Whatsapp Number" placeholder="Enter Your Whatsapp Number" class="text-high-emphasis " variant="outlined" density="compact" required v-model="update_business_details.whatsapp_number"></v-text-field>
                        <small v-if="v$.update_business_details.whatsapp_number.$error" class="text-red">
                Contact Number should be <span class="text-green">03XXXXXXXXX</span>
            </small>
                    </v-col>
                </v-row>
                <v-row class="px-2 mt-2">
                    <v-col cols="12" md="6">
                        <v-text-field label="Facebook Page" placeholder="Enter your Facebook Page" class="text-high-emphasis " variant="outlined" density="compact" required v-model="update_business_details.facebook_page_link"></v-text-field>
                        <small v-if="v$.update_business_details.facebook_page_link.$error" class="text-red">
                Please enter a valid profile link
            </small>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field label="Instagram Page" placeholder="Enter Your Instagram Page" class="text-high-emphasis " variant="outlined" density="compact" required v-model="update_business_details.instagram_page_link"></v-text-field>
                        <small v-if="v$.update_business_details.instagram_page_link.$error" class="text-red">
                Please enter a valid profile link
            </small>
                    </v-col>
                </v-row>
              <v-row class="px-2 mt-2">
                    <v-col cols="12" md="6">
                        <v-text-field label="Website" placeholder="Enter Business Website" class="text-high-emphasis " variant="outlined" density="compact" required v-model="update_business_details.website"></v-text-field>
                        <small v-if="v$.update_business_details.website.$error" class="text-red">
                Please enter a valid website link
            </small>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select :items="business_types" item-title="business_type" item-value="id" label="Business Type" class="text-high-emphasis " variant="outlined" density="compact" readonly v-model="update_business_details.business_type_id"></v-select>
                    </v-col>
                </v-row>
                 <v-row class="mt-2 px-2">
                    <v-col cols="12" md="6">
                        <v-select :items="status" item-title="status" item-value="value" label="Status" class="text-high-emphasis " variant="outlined" density="compact" v-model="update_business_details.status"></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select :items="cities" item-title="name" item-value="id" label="Select City" class="text-high-emphasis " variant="outlined" density="compact" readonly v-model="update_business_details.city_id"></v-select>
                    </v-col>
                </v-row>
                <v-row class="mt-2 px-2">
                    <v-col cols="12">
                        <v-textarea label="Address" placeholder="Enter Business Address" class="text-high-emphasis " variant="outlined" density="compact" required v-model="update_business_details.address" readonly></v-textarea>
                    </v-col>
                </v-row>
                <v-row class="mt-3 px-2">
                    <v-col cols="12" md="6">
                        <v-btn variant="outlined" color="error" rounded="lg" width="100%" @click="business_dialog = false"> close </v-btn>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-btn variant="flat" color="error" rounded="lg" width="100%" @click="UpdateBusiness()"> Update Business </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</v-dialog>
<v-overlay v-model="overlay" persistent class="text-center align-center justify-center">
    <v-progress-circular indeterminate :size="110" :width="15" color="red">
        <v-img class=" text-center ml-auto" src="../assets/pimg.jpeg" style="position: fixed;  width: 70%; height: 70%; border-radius: 100%;"></v-img>
    </v-progress-circular>
</v-overlay>
<v-snackbar v-model="snackbar" vertical color="green" location="top right">
    <p class="text-subtitle-1">{{ snackbar_message }}</p>
</v-snackbar>
</template>

<script>
import axiosInstance from '../interceptors';
const api = axiosInstance;
import {
    useVuelidate
} from '@vuelidate/core'
import {
    required, url,
} from '@vuelidate/validators'
export default {
    emits:["switchScreenWithRequest","setOverlay"],
    data: () => ({
        v$: useVuelidate(),
        all_user_business: [],
        response_message:'',
        overlay: true,
        snackbar_message: '',
        snackbar: false,
        // user_business: [{
        //         id: 1,
        //         name: 'Zaynab Fish Farm',
        //         business_type: "Fish Farm",
        //         email: 'zainabfarm@gmail.com',
        //         website: 'www.zainabfishfarm.com',
        //         status: true,
        //         editable: false
        //     },

        //     {
        //         id: 22,
        //         name: 'stri',
        //         email: null,
        //         business_type: "Fish Farm",
        //         website: null,
        //         status: true,
        //         editable: false
        //     },

        //     {
        //         id: 24,
        //         name: 'strss',
        //         email: null,
        //         business_type: "Fish Farm",
        //         website: null,
        //         status: true,
        //         editable: false
        //     },

        //     {
        //         id: 25,
        //         name: 'strsas',
        //         email: null,
        //         business_type: "Fish Farm",
        //         website: null,
        //         status: true,
        //         editable: false
        //     },

        //     {
        //         id: 38,
        //         name: 'My Farm',
        //         business_type: "Fish Farm",
        //         email: 'nouman.bashir@gmail.com',
        //         website: 'www.fishfarm.com',
        //         status: true,
        //         editable: false
        //     },

        //     {
        //         id: 39,
        //         name: 'zainab',
        //         business_type: "Fish Farm",
        //         email: 'zainbad2@gmail.com',
        //         website: 'zainab_fish.com',
        //         status: true,
        //         editable: false
        //     }
        // ],
        user_business: [],
        cities: [],
        edit_business: false,
        search_business_farm: null,
        business_dialog: false,
        business_types: [],
        update_business_details: { 
            name: '',
            email: '',
            website: '',
            status: '',
            city_id: null,
            address: '',
            contact_number: '',
            whatsapp_number: '',
            facebook_page_link: '',
            instagram_page_link: '',
            business_type_id: null
        },
        status: [{
                status: 'active',
                value: true,
            },
            {
                status: 'In active',
                value: false,
            }
        ],

    }),
    validations() {
        return {
            update_business_details: {
                website: {
                    
                    isValidSite: value => !value || url
                },
                status: {
                    required
                },
                contact_number: {
                    required,
                    isValidPhoneNumber: value => /^\+\d{12}$/.test(value)
                },
                whatsapp_number: {
                    isValidPhoneNumber: value => !value || /^\+\d{12}$/.test(value)
                },
                facebook_page_link: {

                    isValidLink: value => !value || /^(?:https?:\/\/)?(?:www\.)?(mbasic\.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w.-]*\/)*([\w.-]*)+$/.test(value)                
                },
                instagram_page_link: {
                    isValidLink: value => !value || /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/[A-Za-z0-9_.]+$/.test(value)
                },
            }
        }
    },
    mounted() {
        this.GetBusinessTypes();
        this.get_cities();
        this.GetBusinessNames();
        this.GetBusinessDetails();
    },
    watch: {
        handler() {

        }
    },
    methods: {
        GetBusinessNames() {
            api.get('/user/businesses')
                .then((response) => {
                    this.all_user_business = response.data;
                    this.overlay=false;
                })
                .catch((error) => console.error(error));

        },
        get_cities() {
            api.get('/cities')
                .then((response) => {
                    this.cities = response.data;
                    console.log(response);
                    this.overlay=false;
                }).catch((error) => {
                    console.log(error);
                });

        },
        GetBusinessDetails() {
            const params = {
                business_id: this.search_business_farm
            };
            api.get('/businesses', {
                    params
                })
                .then((response) => {
                    this.user_business = response.data;
                    this.response_message = response.message;
                    this.overlay=false;
                })
                .catch((error) => {console.error(error)
                this.response_message = error.response.data.message;
                this.overlay=false;
                });
        },
        GetBusinessTypes() {

            api.get('/business/types').then((response) => {
                this.business_types = response.data;
                console.log("business tyessss", response);
                this.overlay=false;
            }).catch((error) => {
                console.log(error);
            });
            // this.business_types = response.data;

        },

        // setIsLoading(status) {
        //     this.$emit('setOverlay', status);
        // },
        // get_business_specific_orders(business_id, screen) {
        //     this.$emit('switchScreenWithRequest', business_id, screen);
        // },
        EditBusinessDetails(item) {
            this.business_dialog = true;
            console.log(item)
            this.update_business_details.id = item.id;
            this.update_business_details.website = item.website;
            this.update_business_details.email = item.email;
            this.update_business_details.address = item.address;
            this.update_business_details.business_type_id = item.business_type_id;
            this.update_business_details.city_id = item.city_id;
            this.update_business_details.contact_number = item.contact_number;
            this.update_business_details.facebook_page_link = item.facebook_page_link;
            this.update_business_details.name = item.name;
            this.update_business_details.instagram_page_link = item.instagram_page_link;
            this.update_business_details.status = item.status;
            this.update_business_details.whatsapp_number = item.whatsapp_number;
            console.log(this.update_business_details)
        },
        UpdateBusiness() {
            const business_id = this.update_business_details.id;
            this.v$.$validate();
            if (!this.v$.$error) {
                this.overlay=true;
            api.put('/business/' + business_id, this.update_business_details)
                .then(response => {
                    console.log(response)
                    console.log(response.data.id)
                    this.overlay=false;
                    // // this.order_updated.orderStatus = order_update.order_status_id;
                    const pos = this.user_business.findIndex(item => item.id == response.data.id);
                    this.user_business[pos].contact_number = response.data.contact_number;
                    this.user_business[pos].facebook_page_link = response.data.facebook_page_link;
                    this.user_business[pos].instagram_page_link = response.data.instagram_page_link;
                    this.user_business[pos].website = response.data.website;
                    this.user_business[pos].whatsapp_number = response.data.whatsapp_number;
                    this.user_business[pos].status = response.data.status;
                    this.snackbar_message = "Business Updated Successfully";
                    this.business_dialog = false;
                    this.snackbar = true;
                })
                .catch(error => {
                    console.error(error);
                });
            }
            else{
                console.log(this.v$.$errors)
            }

        }

    }
}
</script>

<style scoped>
.style_table{
    background-color: #102536 !; 
    color: #b0b4bb;
}

</style>
