import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';

import router from './routes';
import { loadFonts } from './plugins/webfontloader';
import VueGoogleMaps from '@fawmi/vue-google-maps';

import { initilizingKeycloakFarmer, farmerkeycloak } from '@/plugins/keycloak';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

initilizingKeycloakFarmer();
// import {useAuthStore} from '@/store/authstore';

// router.beforeEach((to)=>{
//     const authStore = useAuthStore();

// })
// router.beforeEach((to, from, next) => {
//     const authStore = useAuthStore();
    
//     // Check if the user is authenticated
//     if (authStore.authentication) {
//       next(); 
//     } else {
//       // Redirect the user to the login page or perform any other action
//     //   next('/login'); // Replace '/login' with the path to your login page
//     console.log("Notauthen")
//     }
//   });


router.beforeEach((to, from, next) => {
    const id = to.params.id;
    if (id){
        if (!Number.isInteger(Number(id))) {
            // Redirect to 404 page if id is not an integer
            next('/notfound');
          } else {
            next();
          }
    }
    else{
        next();
    }
    console.log("asdasd",id)
    // Check if id is an integer
   
  });

loadFonts();
const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.use(vuetify);
app.use(router);
app.use(pinia);
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCXw4Dck1z5nk786nnE8Uw8fzr9axv6A_k',
        libraries: "visualization",
        ProjectID: 'fishfarm-pk1'
    },
});
app.mount('#app');







farmerkeycloak.onAuthSuccess = () => {
    setInterval(() => {
        farmerkeycloak
            .updateToken(60)
            .then((refreshed) => {
                if (refreshed) {
                    localStorage.setItem('accessToken', farmerkeycloak.token);
                    console.log('vueRefreshTokenfarmer', farmerkeycloak.token)
                    localStorage.setItem('vueRefreshToken', farmerkeycloak.refreshToken);
                    localStorage.setItem('idToken', farmerkeycloak.idToken)
                    // console.log(farmerkeycloak.token)
                    // if(localStorage.accessToken){
                    // setAuthHeader(localStorage.accessToken)
                    // }
                } else {
                    console.warn(
                        'Token not refreshed, valid for ' +
                        Math.round(
                            (farmerkeycloak.tokenParsed.exp +
                                farmerkeycloak.timeSkew -
                                new Date().getTime()) /
                            1000
                        ) +
                        ' seconds'
                    );
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, 60000);

};