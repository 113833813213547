<template>
  <v-app>
    <v-main  v-if="authStore.authentication">
      <navBar/>
      <v-layout>
        <v-main  class=" ml-8 adjustmentscreen">
            <router-view></router-view>
        </v-main>
      </v-layout>
      
      
    </v-main>
  </v-app>
</template>

<script>
import {useAuthStore} from '@/store/authstore';
import navBar from '@/components/navBar.vue'
export default {
  name: 'App',

  components: {

    navBar
  },

  data: () => ({
    authStore: useAuthStore(),
  }),
  mounted(){
    this.disableLogs();
  },
  // computed:{
  //   authStore() {
  //            return useAuthStore();
  //           },
  // },
  methods:{
    disableLogs(){
      if(process.env.NODE_ENV == 'production'){
        console.log = () => {};
        console.error = () => {};
        console.debug =() => {};

        // console.log("yes")
      }
    }
  }
}
</script>
<style>
.adjustmentscreen {
    margin-left: 3% !important;
}
.bg{
  border:2px solid red;
}
.v-input__details {
        display: none; /* Hide the content */
    }
</style>