import { defineStore } from 'pinia';

export const useAuthStore = defineStore('authStore', {
  state: () => ({
     authentication:false,
    user:{
        userName:  '',
        email:'',
       
    },

  }),
  getters: {
    getUser: (state) => state.user,
  },
  actions: {
    setAuth(userName,email,auth){
      this.user.userName = userName;
      this.user.email = email; 
      this.authentication=auth
    },
    
  },
  persist: true,
});