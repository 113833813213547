<template>
<v-sheet style="width:95%; height: 100%;">
    <v-container>
        <v-row class="mt-4 ">
            <h1 class="ml-3 heading_styles mb-4">Update Order</h1>
        </v-row>
        <v-row>
          
            <v-col cols="12" md="6">
                <p>Order Number</p>
                <v-text-field  class="text-high-emphasis " variant="outlined" color="primary" density="compact" readonly v-model="order_updated.order_number" append-icon="mdi-lock"/>
            </v-col>
            <v-col cols="12" md="6">
                <p>Business Name</p>
                <v-text-field class="text-high-emphasis " variant="outlined" color="primary" density="compact" readonly v-model="order_updated.business_name" append-icon="mdi-lock"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <p>Order status<span class="text-red">*</span></p>
                <v-select :items="order_status" item-title="order_status" item-value="id" color="primary"  class="text-high-emphasis" variant="outlined" density="compact" v-model="selected_status" append-icon="mdi-lock-open-variant"/>
            </v-col>
            <v-col cols="12" md="6">
                <p>Customer Name</p>
                <v-text-field  class="text-high-emphasis " variant="outlined" density="compact" color="primary" readonly v-model="order_updated.full_name" append-icon="mdi-lock"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <p>Customer Phone No</p>
                <v-text-field  class="text-high-emphasis " variant="outlined" density="compact" color="primary" readonly v-model="order_updated.phone_number" append-icon="mdi-lock"/>
            </v-col>
            <v-col cols="12" md="6">
                <p>Order Amount</p>
                <v-text-field  class="text-high-emphasis" variant="outlined" density="compact" color="primary" readonly v-model="order_updated.total_amount" append-icon="mdi-lock"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <p>Customer Delivery Address</p>
                <v-text-field  class="text-high-emphasis " variant="outlined" density="compact" color="primary" readonly v-model="order_updated.address" append-icon="mdi-lock"/>
            </v-col>
            <v-col cols="12" md="6">
                <p>Instructions</p>
                <v-text-field  class="text-high-emphasis " variant="outlined" density="compact" color="primary" readonly v-model="order_updated.instructions" append-icon="mdi-lock"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12"  class="text-end" >
                <v-btn variant="flat" color="error" rounded="lg"  @click="UpdateOrder()"> Update Order </v-btn>
            </v-col>
        </v-row>

    </v-container>
    

</v-sheet>
<v-overlay v-model="overlay" persistent class="text-center align-center justify-center">
    <v-progress-circular indeterminate :size="110" :width="15" color="red">
        <v-img class=" text-center ml-auto" src="../assets/pimg.jpeg" style="position: fixed;  width: 70%; height: 70%; border-radius: 100%;"></v-img>
    </v-progress-circular>
</v-overlay>
<v-snackbar v-model="snackbar" vertical color="green" location="top right">
    <p class="text-subtitle-1">{{ snackbar_message }}</p>
</v-snackbar>
</template>

<script>
import axiosInstance from '../interceptors';
const api = axiosInstance;
export default {
    data: () => ({
        order_updated: [],
        order_status: [],
        selected_status: null,
        overlay: true,
        snackbar_message: '',
        snackbar: false,
    }),
    mounted(){
        this.GetOrderStatus();
    },
    computed: {
        order() {
            if(this.$route.params.id)
            {
                this.GetOrder(this.$route.params.id);
            }
            return "a"
        }
    }, 
    methods:{
        GetOrderStatus() {
            api.get('/order/statuses')
                .then((response) => {
                    this.order_status = response.data;
                    
                })
                .catch((error) => console.error(error));
        },
        UpdateOrder(){
            this.overlay = true;
            const order_update ={
               
               order_status_id: this.selected_status
           }
           const order_id = this.order_updated.id;

           api.put('/order/tracking/'+ order_id, order_update)
            .then(response => {     
                console.log(response)
                this.snackbar_message = "Order status updated successfully";
                    this.snackbar = true;
                    setTimeout(() => {
                        this.$router.push("/orders");
                    }, 1000);
                
            })
            .catch(error => {
            console.error(error);
            });
        },
        GetOrder(order_id){
            console.log(order_id)
            api.get('/order/'+order_id)
                .then((response) => {

                    this.order_updated = response.data;
                    this.selected_status= response.data.status_id;
                    this.overlay= false;
                    console.log("response", response)
                })
                .catch((error) => {
                    console.error(error);
                // this.response_message = error
                });
        }
    }
}
</script>

<style>

</style>
