import axios from 'axios';

const axiosInstance = axios.create({
    // baseURL:'http://159.89.7.6:8003',  
  baseURL: process.env.VUE_APP_BASE_URL,
//   timeout: 5000, // if i want to set time out
    headers: {
        'Content-Type': 'application/json'
    },
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${localStorage.accessToken}`
    return config;
  },
  (error) => {
    
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    console.log(response)
    return response.data;
  },
  (error) => {
    console.error('Response error:', error);
    
    return Promise.reject(error);
  }
);

export default axiosInstance;
