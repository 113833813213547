<template>
<v-sheet style="width:95%; height: 100%;">
    <v-container class="">
        <v-row class="mt-4 ">
            <h1 class="ml-3 heading_styles mb-4">Farmer Product</h1>

        </v-row>
        <v-row>
            <!-- <v-col cols="12" md="6"> </v-col> -->
            <v-col cols="12" md="6">
                <p>Select Business <span v-if="product_edit !== true" class="text-red">*</span></p>
                <v-select :items="all_user_business" item-title="name" item-value="id" class=" " :color="v$.new_product.product_id.$error ? 'red' : 'primary'" focuse variant="outlined" density="compact" v-model="new_product.business_id" :readonly="product_edit" :append-icon="product_edit ? 'mdi-lock' : 'mdi-lock-open-variant'" />
                <small v-if="v$.new_product.business_id.$error" class="text-red">
                    Please select the business name
                </small>
            </v-col>
            <v-col cols="12" md="6">
                <p>Select Product <span v-if="product_edit !== true" class="text-red">*</span> </p>
                <v-select :items="products" item-title="name" item-value="id" class="text-high-emphasis " :color="v$.new_product.product_id.$error ? 'red' : 'primary'" variant="outlined" v-model="new_product.product_id" density="compact" :readonly="product_edit" :append-icon="product_edit ? 'mdi-lock' : 'mdi-lock-open-variant'" />
                <small v-if="v$.new_product.product_id.$error" class="text-red">
                    Please select the product
                </small>

            </v-col>
        </v-row>
        <v-row>

            <v-col cols="12" md="6">
                <p>Select Product Source <span v-if="product_edit !== true" class="text-red">*</span></p>
                <v-select :items="sources" item-title="source" item-value="id" class="text-high-emphasis " :color="v$.new_product.product_id.$error ? 'red' : 'primary'" v-model="new_product.product_source_id" variant="outlined" density="compact" :readonly="product_edit" :append-icon="product_edit ? 'mdi-lock' : 'mdi-lock-open-variant'" />
                <small v-if="v$.new_product.product_source_id.$error" class="text-red">
                    Please enter the product source
                </small>

            </v-col>
            <v-col cols="12" md="6">
                <p>Select Measuring Unit <span v-if="product_edit !== true" class="text-red">*</span> </p>
                <v-select :items="measuring_units" item-title="name" item-value="id" class="text-high-emphasis " :color="v$.new_product.product_id.$error ? 'red' : 'primary'" v-model="new_product.measuring_unit_id" variant="outlined" density="compact" :readonly="product_edit" :append-icon="product_edit ? 'mdi-lock' : 'mdi-lock-open-variant'" />
                <small v-if="v$.new_product.measuring_unit_id.$error" class="text-red">
                    Please enter the measuring unit
                </small>

            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <p>Set Product Status <span class="text-red">*</span></p>
                <v-select :items="status" item-title="status" item-value="value" class="text-high-emphasis " :color="v$.new_product.product_id.$error ? 'red' : 'primary'" variant="outlined" v-model="new_product.status" density="compact" append-icon="mdi-lock-open-variant" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <p>Enter Unit Price <span class="text-red">*</span> </p>
                <v-text-field type="number" class="text-high-emphasis " variant="outlined" density="compact" :color="v$.new_product.product_id.$error ? 'red' : 'primary'" v-model="new_product.unit_price" append-icon="mdi-lock-open-variant"></v-text-field>
                <small v-if="v$.new_product.unit_price.$error" class="text-red">
                    Please enter a positive number
                </small>
            </v-col>
            <v-col cols="12" md="6">
                <p>Enter Minimum Order <span class="text-red">*</span> </p>
                <v-text-field type="number" class="text-high-emphasis " variant="outlined" density="compact" :color="v$.new_product.product_id.$error ? 'red' : 'primary'" v-model="new_product.minimum_order" append-icon="mdi-lock-open-variant"></v-text-field>
                <small v-if="v$.new_product.minimum_order.$error" class="text-red">
                    Please enter a positive number
                </small>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <p>Enter Maximum Order <span class="text-red">*</span> </p>
                <v-text-field type="number" class="text-high-emphasis " variant="outlined" density="compact" :color="v$.new_product.product_id.$error ? 'red' : 'primary'" v-model="new_product.maximum_order" append-icon="mdi-lock-open-variant"></v-text-field>
                <small v-if="v$.new_product.maximum_order.$error" class="text-red">
                    Please enter a positive number
                </small>
            </v-col>
            <v-col cols="12" md="6">
                <p>Select Product Availability <span class="text-red">*</span> </p>
                <v-checkbox v-model="new_product.in_stock" color="red-darken-3" label="In Stock" append-icon="mdi-lock-open-variant" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="text-end">
                <v-btn variant="flat" color="error" rounded="lg" @click="ManuplateProduct()"> {{ product }} </v-btn>
            </v-col>
        </v-row>

    </v-container>

</v-sheet>
<v-overlay v-model="overlay" persistent class="text-center align-center justify-center">
    <v-progress-circular indeterminate :size="110" :width="15" color="red">
        <v-img class=" text-center ml-auto" src="../assets/pimg.jpeg" style="position: fixed;  width: 70%; height: 70%; border-radius: 100%;"></v-img>
    </v-progress-circular>
</v-overlay>
<v-snackbar v-model="snackbar" vertical color="green" location="top right">
    <p class="text-subtitle-1">{{ snackbar_message }}</p>
</v-snackbar>
</template>

<script>
import axiosInstance from '../interceptors';
const api = axiosInstance;
import {
    useVuelidate
} from '@vuelidate/core';
import {
    required,
    integer,
    minValue
} from '@vuelidate/validators';

export default {
    data: () => ({
        v$: useVuelidate(),
        overlay: false,
        snackbar_message: '',
        snackbar: false,
        products: [],
        all_user_business: [],
        sources: [],
        measuring_units: [],
        product_edit: false,
        search_product: null,
        status: [{
                status: 'active',
                value: true,
            },
            {
                status: 'In active',
                value: false,
            }
        ],
        new_product: {
            id: null,
            business_id: null,
            product_id: null,
            product_source_id: null,
            measuring_unit_id: null,
            unit_price: null,
            minimum_order: null,
            maximum_order: null,
            in_stock: false,
            status: false
        },
    }),
    validations() {
        return {
            new_product: {
                business_id: {
                    required,

                },
                product_id: {
                    required,
                },
                product_source_id: {
                    required,
                },
                measuring_unit_id: {
                    required,
                },
                unit_price: {
                    required,
                    integer,
                    minValue: minValue(0)
                },
                minimum_order: {
                    required,
                    integer,
                    minValue: minValue(0)
                },
                maximum_order: {
                    required,
                    integer,
                    minValue: minValue(0)
                },
            }
        }
    },
    mounted() {
        // this.FilterProducts();
        this.GetMeasuringUnits();
        this.GetProducts();
        this.GetProductSource();
        this.GetBusinessNames();
    },
    computed: {
        product() {
            let btn_message;
            // let response;
            if (this.$route.params.id) {
                btn_message = 'Update Product'
                this.GetBusinessProduct(this.$route.params.id)
                return btn_message;
            } else {
                btn_message = 'Add Product'
                return btn_message
            }
        }
    },

    methods: {
        GetBusinessProduct(product_id) {
            api.get('/business/product/' + product_id).
            then((response) => {
                console.log(response);
                this.new_product = response.data
                this.product_edit = true;
            }).
            catch((error) => {
                if (error.response.status == 404)
                    {
                        this.$router.push("/notfound")
                    }
                //     console.error(error.response.status);
                console.log(error.response.status);
            });
            // http://159.89.7.6:8003/business/product/1
        },
        GetBusinessNames() {
            api.get('/user/businesses').
            then((response) => {
                this.all_user_business = response.data;
                console.log(response);
            }).catch((error) => {
                console.error(error);
            });
        },
        GetMeasuringUnits() {
            api.get('/measuring_units').then((response) => {
                console.log(response);
                this.measuring_units = response.data;
            }).catch((error) => {
                console.log(error)
            });
        },
        GetProductSource() {
            api.get('/product/sources').then((response) => {
                console.log(response);
                this.sources = response.data;
            }).catch((error) => {
                console.log(error);
            });
        },
        GetProducts() {
            api.get('/product/names').then((response) => {
                console.log(response);
                this.products = response.data;
            }).catch((error) => {
                console.log(error);
            });
        },
        ManuplateProduct() {
            this.v$.$validate();
            if (!this.v$.$error) {

                this.new_product.unit_price = parseFloat(this.new_product.unit_price);
                this.new_product.minimum_order = parseFloat(this.new_product.minimum_order);
                this.new_product.maximum_order = parseFloat(this.new_product.maximum_order);
                this.overlay = true;
                if (this.product_edit) {
                    this.UpdateProduct();
                } else {
                    this.AddNewProduct();
                }
            }

        },
        UpdateProduct() {
            console.log(this.new_product);
            const business_product_id = this.new_product.id;
            api.put('/business/product/' + business_product_id, this.new_product)
                .then(response => {
                    console.log(response);
                    this.snackbar_message = "Product Updated Successfully";
                    this.snackbar = true;
                    setTimeout(() => {
                        this.$router.push("/products");
                    }, 1000);

                })
                .catch(error => {
                    console.error(error);
                });
        },
        AddNewProduct() {
            api.post('/business/product', this.new_product)
                .then(response => {
                    console.log("New business", response);
                    this.snackbar_message = "Product Added Successfully";
                    this.snackbar = true;
                    setTimeout(() => {
                        this.$router.push("/products");
                    }, 1000);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
}
</script>

<style>
.v-input__details {
    display: none;
    /* Hide the content */
}
</style>
