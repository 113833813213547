<template>
<v-sheet style="width:95%; height: 100%;">
    <v-row class="mt-4 ">
        <h1 class="ml-3 heading_styles">My Products</h1>
    </v-row>
    <v-row>

        <v-col cols="12">
            <v-row>
                <v-col cols="12" md="6">
                    <v-row>

                        <v-col cols="12" md="6">
                            <v-select :items="products" item-title="name" item-value="id" label="Filter by Products" class="text-high-emphasis " variant="outlined" density="compact" v-model="search_product" clearable></v-select>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-select :items="all_user_business" item-title="name" item-value="id" label="Filter by Business Name" class="text-high-emphasis " variant="outlined" density="compact" v-model="search_business" clearable></v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="6">
                            <v-select :items="sources" item-title="source" item-value="id" label="Filter by Product Source" class="text-high-emphasis " variant="outlined" density="compact" v-model="search_source" clearable></v-select>

                        </v-col>

                        <v-col cols="12" md="6">
                            <v-select :items="measuring_units" item-title="name" item-value="id" label=" Filter by Measuring Units" class="text-high-emphasis" variant="outlined" density="compact" v-model="search_unit" clearable></v-select>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6">
                    <v-row>
                        <v-col cols="12" class="text-end">
                            <v-btn color="red" prepend-icon="mdi-shape-square-plus" variant="outlined" :width="$vuetify.display.xs?'100%':''" size="large" @click="ManuplateProduct() ">
                                Add New Product
                            </v-btn>
                        </v-col>
                        <v-col cols="12" class="text-end">
                            <v-btn variant="outlined" prepend-icon="mdi-filter" :width="$vuetify.display.xs?'100%':'235px'" size="large" @click="FilterProducts(), overlay=true">
                                Apply Filter
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-col>
            </v-row>
        </v-col>

    </v-row>
    <v-row>
<!-- data{{ farmer_products }} -->
        <v-col cols="12">
            <v-table height="60dvh" fixed-header>
                <thead>
                    <tr style="background-color: #102536; color: #b0b4bb;">
                        <td> PRODUCT NAME</td>
                        <td> BUSINESS NAME</td>
                        <td> PRODUCT SOURCE</td>
                        <td> MEASURING UNIT</td>
                        <td> UNIT PRICE</td>
                        <td> MINIMUM ORDER</td>
                        <td> MAXIMUM ORDER</td>
                        <td> IN STOCK</td>
                        <td> STATUS</td>
                        <td> ACTION </td>
                    </tr>
                </thead>
                    <tbody>
                        <tr v-for="(product , index ) in farmer_products" :key="product" @click="ManuplateProduct(product)" class="style_table">
                            <td> {{ product.product_name}}</td>
                            <td> {{ product.business_name }} </td>
                            <td> {{ product.source }}</td>
                            <td> {{ product.measuring_unit_name }} </td>
                            <td> Rs. {{ product.unit_price }}</td>
                            <td> {{product.minimum_order }} {{ product.measuring_unit_name }}</td>
                            <td> {{ product.maximum_order }} {{ product.measuring_unit_name }}</td>
                            <td v-if="product.in_stock" class="text-green"> Avalaible</td>
                            <td v-else class="text-red"> Not Avalaible</td>
                            <td v-if="product.status" class="text-green"> Active</td>
                            <td v-else class="text-red">In Active</td>
                            <td>
                                <v-btn icon="mdi-table-edit" variant="text" color="green" class="ml-n2" @click="ManuplateProduct(product)"> </v-btn>
                                <v-btn icon="mdi-delete-outline" variant="text" color="red" class="ml-n2" disabled @click="delete_business_product=product; delete_business_product_index=index; product_delete_dialog=true"> </v-btn>
                            </td>
                        </tr>
                    
                        <tr v-if="response_message" class="text-center text-red text-h6">
                           <td colspan="10">{{ response_message }}</td>
                        </tr>
                </tbody>
            </v-table>
            <v-pagination v-if="total_pages>1" class="ml-auto" density="compact" :length="total_pages" v-model="current_page" :total-visible="5"></v-pagination>
        </v-col>
    </v-row>

</v-sheet>

<!-- <v-dialog v-model="product_dialog" transition="dialog-top-transition">
    <v-container>
        <v-card class="mx-auto mt-16  rounded-xl elevation-7" color="white" max-width="600">
            <v-card-title class="text-center text-h5 mt-4 font-weight-bold"> ADD NEW PRODUCT </v-card-title>
            <v-card-text>
                <v-row class="px-2 mt-2">
                    <v-col cols="12">
                        <v-select :items="all_user_business" item-title="name" item-value="id" label=" Selct Business" class="text-high-emphasis " variant="outlined" density="compact" v-model="new_product.business_id" :readonly="edit_product_record"></v-select>
                        <small v-if="v$.new_product.business_id.$error" class="text-red">
                            Please select the business name
                        </small>
                    </v-col>
                </v-row>
                <v-row class="px-2 mt-2">
                    <v-col cols="12">
                        <v-select :items="products" item-title="name" item-value="id" label="Products" class="text-high-emphasis " variant="outlined" density="compact" v-model="new_product.product_id" :readonly="edit_product_record"></v-select>
                        <small v-if="v$.new_product.product_id.$error" class="text-red">
                            Please select the product
                        </small>
                    </v-col>
                </v-row>
                <v-row class="px-2 mt-2">
                    <v-col cols="12">
                        <v-select :items="sources" item-title="source" item-value="id" label="Product Source" class="text-high-emphasis " variant="outlined" density="compact" v-model="new_product.product_source_id" :readonly="edit_product_record"></v-select>
                        <small v-if="v$.new_product.product_source_id.$error" class="text-red">
                            Please enter the product source
                        </small>
                    </v-col>
                </v-row>
                <v-row class="px-2 mt-2">
                    <v-col cols="6">
                        <v-select :items="measuring_units" item-title="name" item-value="id" label="Measuring Unit" class="text-high-emphasis " variant="outlined" density="compact" v-model="new_product.measuring_unit_id" :readonly="edit_product_record"></v-select>
                        <small v-if="v$.new_product.measuring_unit_id.$error" class="text-red">
                            Please enter the measuring unit
                        </small>
                    </v-col>
                    <v-col cols="6">
                        <v-select :items="status" item-title="status" item-value="value" label="Status" class="text-high-emphasis " variant="outlined" density="compact" v-model="new_product.status"></v-select>

                    </v-col>
                </v-row>
                <v-row class="px-2 mt-2">
                    <v-col cols="12" md="6">
                        <v-text-field label="Unit Price" type="number" class="text-high-emphasis " variant="outlined" density="compact" v-model="new_product.unit_price"></v-text-field>
                        <small v-if="v$.new_product.unit_price.$error" class="text-red">
                            Please enter a positive number
                        </small>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field label="Minimum Order" type="number" class="text-high-emphasis " variant="outlined" density="compact" v-model="new_product.minimum_order"></v-text-field>
                        <small v-if="v$.new_product.minimum_order.$error" class="text-red">
                            Please enter a positive number
                        </small>
                    </v-col>
                </v-row>
                <v-row class="px-2 mt-2">
                    <v-col cols="12" md="6">
                        <v-text-field label="Maximum Order" type="number" class="text-high-emphasis " variant="outlined" density="compact" v-model="new_product.maximum_order"></v-text-field>
                        <small v-if="v$.new_product.maximum_order.$error" class="text-red">
                            Please enter a positive number
                        </small>
                    </v-col>
                    <v-col cols="6" md="6">
                        <v-checkbox v-model="new_product.in_stock" color="red-darken-3" label="In Stock"> </v-checkbox>
                    </v-col>
                </v-row>
                <v-row class="mt-3 px-2">
                    <v-col cols="12" md="6">
                        <v-btn variant="outlined" color="error" rounded="lg" width="100%" @click="product_dialog = false"> close </v-btn>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-btn variant="flat" color="error" rounded="lg" width="100%" @click="AddNewProduct()"> {{ button_text }} </v-btn>
                        <v-btn v-else variant="flat" color="error" rounded="lg" width="100%" @click="add_product()"> Add Product </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</v-dialog> -->
<v-overlay v-model="overlay" persistent class="text-center align-center justify-center">
    <v-progress-circular indeterminate :size="110" :width="15" color="red">
        <v-img class=" text-center ml-auto" src="../assets/pimg.jpeg" style="position: fixed;  width: 70%; height: 70%; border-radius: 100%;"></v-img>
    </v-progress-circular>
</v-overlay>
</template>

<script>
import axiosInstance from '../interceptors';
const api = axiosInstance;
import {
    useVuelidate
} from '@vuelidate/core'
import {
    required,
    integer,
    minValue
} from '@vuelidate/validators'

export default {
    name: 'MyproductsComponent',
    // emits: ["setOverlay", "setSnackbar"],
    data: () => ({
        v$: useVuelidate(),
        overlay:true,
        total_pages: null,
        current_page: null,
        product_dialog: false,
        response_message: '',
        button_text: '',
        products: [],
        all_user_business: [],
        sources: [],
        measuring_units: [],
        farmer_products: [],
        search_business: null,
        search_product: null,
        search_source: null,
        search_unit: null,
        edit_product_record: false,
        new_product: {
            id: null,
            business_id: null,
            product_id: null,
            product_source_id: null,
            measuring_unit_id: null,
            unit_price: null,
            minimum_order: null,
            maximum_order: null,
            in_stock: false,
            status: false
        },
        update_product: {},
        status: [{
                status: 'active',
                value: true,
            },
            {
                status: 'In active',
                value: false,
            }
        ],
        from_reg_farms: ''

    }),
    validations() {
        return {
            new_product: {
                business_id: {
                    required,

                },
                product_id: {
                    required,
                },
                product_source_id: {
                    required,
                },
                measuring_unit_id: {
                    required,
                },
                unit_price: {
                    required,
                    integer,
                    minValue: minValue(0)
                },
                minimum_order: {
                    required,
                    integer,
                    minValue: minValue(0)
                },
                maximum_order: {
                    required,
                    integer,
                    minValue: minValue(0)
                },
            }
        }
    },
    mounted() {
        this.FilterProducts();
        this.GetMeasuringUnits();
        this.GetProducts();
        this.GetProductsSource();
        this.GetBusinessNames();
    },
    watch: {
        from_reg_farms: {
            handler() {
                this.search_business = this.from_reg_farms;
                this.FilterProducts();
            },
        }
    },
    // computed:{
    //     from_reg_farms

    // },
    methods: {
        setValueFromSubling(value) {
            // console.log("asdasasdasasd", value)
            this.search_business = value;
            this.FilterProducts();
        },
        GetBusinessNames() {
            api.get('/user/businesses').
            then((response) => {
                this.all_user_business = response.data;
                this.overlay=false;
                console.log(response);
            }).catch((error) => {
                console.error(error);
            });
        },
        FilterProducts() {
            const params = {
                business: this.search_business,
                product: this.search_product,
                source: this.search_source,
                unit: this.search_unit,
                page_number: this.current_page
            };
            console.log(params)
            api.get('/business/products', {
                params
            }).then((response) => {
                console.log(response.data);
                this.farmer_products = response.data;
                this.total_pages = response.pages;
                this.current_page = response.current_page;
                this.response_message = response.message;
                this.overlay=false;
                // this.setIsLoading(false);
            }).catch((error) => {
                console.log(error);
            });
        },
        GetMeasuringUnits() {
            api.get('/measuring_units').then((response) => {
                console.log(response);
                this.measuring_units = response.data;
                this.overlay=false;
            }).catch((error) => {
                console.log(error)
            });
        },
        GetProductsSource() {
            api.get('/product/sources').then((response) => {
                console.log(response);
                this.sources = response.data;
                this.overlay=false;
            }).catch((error) => {
                console.log(error);
            });
        },
        GetProducts() {
            api.get('/product/names').then((response) => {
                console.log(response);
                this.products = response.data;
                this.overlay=false;
            }).catch((error) => {
                console.log(error);
            });

        },
        // setIsLoading(status) {
        //     this.$emit('setOverlay', status);
        // },
        // edit_product(product_data) {
        //     this.product_dialog = true;
        //     console.log(this.new_product)
        //     this.assignValueToProduct(product_data)
        // },
        ManuplateProduct(product_data = null) {
            console.log(product_data)
            // this.$router.push("/product/"+product_data.id)
            // console.log("product_data", product_data)
            // this.product_dialog = true;

            this.edit_product_record = product_data ? true : false;
            if(product_data){
                this.$router.push("/product/"+product_data.id)
            }
            else{
                this.$router.push("/product")
            }
            // this.button_text = this.edit_product_record ? 'Update Product' : 'Add Product';
            // this.new_product.id = product_data ? product_data.id : null;
            // this.new_product.business_id = product_data ? product_data.business_id : null;
            // this.new_product.product_id = product_data ? product_data.product_id : null;
            // this.new_product.product_source_id = product_data ? product_data.product_source_id : null;
            // this.new_product.measuring_unit_id = product_data ? product_data.measuring_unit_id : null;
            // this.new_product.unit_price = product_data ? product_data.unit_price : null;
            // this.new_product.minimum_order = product_data ? product_data.minimum_order : null;
            // this.new_product.maximum_order = product_data ? product_data.maximum_order : null;
            // this.new_product.in_stock = product_data ? product_data.in_stock : false;
            // this.new_product.status = product_data ? product_data.status : false;
            // console.log(this.new_product, this.edit_product_record)

        },
        // AddNewProduct() {
        //     this.new_product.unit_price = parseFloat(this.new_product.unit_price)
        //     this.new_product.minimum_order = parseFloat(this.new_product.minimum_order)
        //     this.new_product.maximum_order = parseFloat(this.new_product.maximum_order)
        //     // this.product_dialog = true
        //     console.log(this.new_product);

        //     this.v$.$validate();
        //     if (!this.v$.$error) {
        //         this.setIsLoading(true);
        //         if (this.edit_product_record) {
        //             console.log("if", this.edit_product_record)
        //             const business_product_id = this.new_product.id;
        //             api.put('/business/product/' + business_product_id, this.new_product)
        //                 .then(response => {
        //                     const pos = this.farmer_products.findIndex(item => item.id == response.data.id);
        //                     this.farmer_products[pos].measuring_unit_id = response.data.measuring_unit_id,
        //                         this.farmer_products[pos].unit_price = response.data.unit_price,
        //                         this.farmer_products[pos].minimum_order = response.data.minimum_order,
        //                         this.farmer_products[pos].maximum_order = response.data.maximum_order,
        //                         this.farmer_products[pos].in_stock = response.data.in_stock,
        //                         this.farmer_products[pos].status = response.data.status
        //                     this.product_dialog = false;
        //                     this.invokeSnackbar("Product Updated successfully");
        //                     this.setIsLoading(false);

        //                 })
        //                 .catch(error => {
        //                     console.error(error);
        //                 });
        //         } else {

        //             console.log("else", this.new_product)

        //             api.post('/business/product', this.new_product)
        //                 .then(response => {
        //                     console.log("New business", response)
        //                     this.farmer_products.push(response.data)
        //                     this.product_dialog = false;
        //                     this.invokeSnackbar("Product Added successfully");
        //                     this.setIsLoading(false);
        //                 })
        //                 .catch(error => {
        //                     console.error(error);
        //                 });
        //         }
        //     } else {
        //         console.log(this.v$.$errors)
        //     }

        //     // this.product_editable = true
        //     // this.product_dialog = true

        // },

        // invokeSnackbar(message) {
        //     this.$emit('setSnackbar', true, message);
        // }

    }
}
</script>

<style scoped>
.style_table{
    cursor: pointer;
}
.style_table:hover{
    background-color: rgb(240, 240, 240);
    /* color: white; */
}
</style>
