import { createWebHistory ,createRouter } from "vue-router";
import MydashboardComponent from './components/MydashboardComponent.vue';
import MyfarmsComponent from './components/MyfarmsComponent.vue';
import MyordersComponent from './components/MyordersComponent.vue';
import MyproductsComponent from './components/MyproductsComponent.vue';
import AddbusinessComponent from './components/AddbusinessComponent.vue';
import ContactusComponent from './components/ContactusComponent.vue';
import EditproductComponent from './components/EditproductComponent.vue';
import EditorderComponent from './components/EditorderComponent.vue';
import notFound from './components/notFound.vue'
const routes=[
    {
        name:'MydashboardComponent',
        path:'/',
        component:MydashboardComponent
    },
    
    {
        name:'MyfarmsComponent',
        path:'/farms',
        component:MyfarmsComponent
    },
    {
        name:'MyordersComponent',
        path:'/orders',
        component:MyordersComponent
    },
    {
        name:'AddbusinessComponent',
        path:'/add_business',
        component:AddbusinessComponent
    },
    {
        name:'MyproductsComponent',
        path:'/products',
        component:MyproductsComponent
    },
    {
        name:'ContactusComponent',
        path:'/contact_us',
        component:ContactusComponent
    },
    {
        name:'EditorderComponent',
        path:'/order/:id?',
        component:EditorderComponent,
          
    },
    {
        name:'EditproductComponent',
        path:'/product/:id?',
        component:EditproductComponent,
          
    },
    {
        name:'notfound',
        path:'/notfound',
        component:notFound
    },
    { path: '/:catchAll(.*)', redirect: '/notfound' },
    // {
    //     name:'ContactusComponent',
    //     path:'/contact_us',
    //     component:ContactusComponent
    // },
];
const router=createRouter({
    history:createWebHistory(),
    routes
});
export default router;