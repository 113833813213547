<template>
<v-sheet style="width:95%; height: 100%;">
    <v-row class="mt-4 ">
        <h1 class="ml-3 heading_styles mb-4">Dashboard</h1>
    </v-row>

    <v-row>
        <v-col cols="6" md="3" v-for="item in items" :key="item">
            <v-card class="DashboardColourCards rounded text-white ml-2 ">
                <v-row class="pa-4 pa-md-8 ">
                    <v-col cols="8 ">
                        <p class="text-caption text-xl-h6">{{item.name}}</p>
                        <p class="text-body-1 text-sm-h4 mt-1 font-weight-bold">{{ item.numbers }}</p>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="4" class="my-auto text-left pa-1 ">
                        <span class="material-symbols-outlined " :style="$vuetify.display.smAndUp? 'font-size: 320%': 'font-size: 170%'" >

                            <v-icon class="">{{ item.icon }}</v-icon>
                        </span>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <h2 class="heading_styles "> Recent Orders</h2>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-table height="60dvh" fixed-header>
                <thead>
                    <tr style="background-color: #102536; color: #b0b4bb;">
                        <td>ORDER NO</td>
                        <td>BUSINESS NAME</td>
                        <td>ORDER STATUS</td>
                        <td>USER NAME</td>
                        <td>PHONE NO</td>
                        <td>TOTAL AMOUNT</td>
                        <td>DELIVERY ADDRESS</td>
                        <td>INSTRUCTIONS</td>
                    </tr>
                </thead>
                    <tbody>
                    <tr v-for="ord in order" :key="ord">
                        <td> {{ ord.order_number}}</td>
                        <td> {{ ord.name }} </td>
                        <td>
                            <v-chip class="ma-2" :color="getStatusColor(ord.order_status)" label>
                                {{ord.order_status}}
                            </v-chip>
                        </td>
                        <td> {{ ord.full_name }} </td>
                        <td> {{ ord.phone_number }}</td>
                        <td class="text-green"> Rs. {{ ord.total_amount }}</td>
                        <td> {{ ord.address }}</td>
                        <td> {{ ord.instructions }}</td>

                    </tr>
                    <tr v-if="response_message" class="text-center text-red text-h6">
                           <td colspan="10">{{ response_message }}</td>
                        </tr>
                </tbody>

            </v-table>

            <v-pagination v-if="total_pages>1" class="ml-auto" density="compact" :length="total_pages" v-model="current_page" :total-visible="5"></v-pagination>

        </v-col>
    </v-row>

</v-sheet>
<v-overlay v-model="overlay" persistent class="text-center align-center justify-center">
    <v-progress-circular indeterminate :size="110" :width="15" color="red">
        <v-img class=" text-center ml-auto" src="../assets/pimg.jpeg" style="position: fixed;  width: 70%; height: 70%; border-radius: 100%;"></v-img>
    </v-progress-circular>
</v-overlay>
</template>

<script>
import axiosInstance from '../interceptors';
const api = axiosInstance;
export default {
    emits: ["setOverlay"],
    data: () => ({
        order_status: null,
        total_pages: null,
        current_page: 1,
        response_message:'',
        overlay: true,
        order: [],
        items: [
            {
                name: 'Total Orders',
                icon: 'mdi-bag-personal-outline',
                numbers: null,
                related_name: 'total_orders'
            },
            {
                name: 'Products',
                icon: 'mdi-shape',
                numbers: null,
                related_name: 'total_products'
            },
            {
                name: 'Orders Delivered',
                icon: 'mdi-truck-delivery-outline',
                numbers: null,
                related_name: 'delivered_orders'
            },
            {
                name: 'Sales',
                icon: 'mdi-cash',
                numbers: null,
                related_name: 'total_sales'
            }
        ],
        new_orders: [{
                day: 'Today',
                numbers: 4
            },
            {
                day: 'This Week',
                numbers: 24
            },
            {
                day: 'This Month',
                numbers: 144
            },
        ],
        delivered_orders: [{
                day: 'Today',
                numbers: 4
            },
            {
                day: 'This Weak',
                numbers: 14
            },
            {
                day: 'This Month',
                numbers: 144
            },
        ]
    }),
    mounted() {
        this.GetOrderStatus();
        // this.GetBusinessSpecificOrders();

    },
    methods: {
        GetBusinessSpecificOrders() {
            const params = {
                status_id: this.order_status,
                business_id: null,
                page_number: this.current_page
            }

            api.get('/business/orders', {
                    params
                })
                .then((response) => {
                    console.log("response1111", response)
                    this.response_message = response.message;
                    this.order = response.data;
                    // this.response_message = response.message;
                    this.total_pages = response.pages;
                    for (let item in this.items) {
                        if (this.items[item].related_name === 'delivered_orders') {
                            this.items[item].numbers = response.delivered_orders;
                        } else if (this.items[item].related_name === 'total_orders') {
                            this.items[item].numbers = response.total_orders;
                        } else if (this.items[item].related_name === 'total_products') {
                            this.items[item].numbers = response.total_products;
                        } else {
                            this.items[item].numbers = response.total_sales;
                        }
                    }
                    // this.setIsLoading(false);
                })
                .catch((error) => {
                    // console.error("this is error",error);
                    this.response_message = error.response.data.message;
                });
        },
        GetOrderStatus() {
            api.get('/order/statuses')
                .then((response) => {
                    let status = response.data.filter(item => item.order_status == 'New');
                    this.order_status = status[0].id;
                    this.GetBusinessSpecificOrders();
                    this.overlay=false;
                })
                .catch((error) => console.error(error));
        },
        // setIsLoading(status) {
        //     this.$emit('setOverlay', status);
        // },
        getStatusColor(orderStatus) {

            switch (orderStatus) {
                case 'Dispatched':
                    return 'orange';
                case 'Delivered':
                    return 'success';
                case 'Confirmed':
                    return 'primary';
                case 'New':
                    return 'blue';
                case 'Cancelled':
                    return 'red';
                default:
                    return '';
            }
        }
    }
}
</script>

<style>
.DashboardColourCards {

    background: -webkit-linear-gradient(left, #D85E63, #B92F35);
    background: linear-gradient(to right, #D85E63, #B92F35);
}

.DashboardSmallCards {
    color: black;
    background-color: rgba(247, 222, 223, 0.7);
    width: 31%;
}

.DashboardSmallCards:hover {
    cursor: pointer;
    color: white;
    background: -webkit-linear-gradient(left, #D85E63, #B92F35);
    background: linear-gradient(to right, #D85E63, #B92F35);
}

.flex-container {
    display: flex;
    align-items: stretch;

}

@media only screen and (max-width: 768px) {
    .DashboardSmallCards {
        width: 100%;
    }
}

@media screen and (min-width: 480px) {
    body {
        background-color: lightgreen;
    }
}

/* .my-pagination>.v-pagination__list>li {
    background: #DDF6FF !important;
    color: black !important;
}

.my-pagination>.v-pagination__list>li>.v-btn:focus {
    background: #FB585E !important;
    color: white !important;

} */

/* .flex-container > div {
  color: white;
  margin: 10px;
  /* line-height: 75px;
  font-size: 30px; */
/* }  */
</style>
