import Keycloak from 'keycloak-js';
// import setAuthHeader from './setAuthHeader';
import {useAuthStore} from '@/store/authstore'


const keycloakConfig = {
      url:process.env.VUE_APP_KEYCLOAK_URL,
      realm: process.env.VUE_APP_KEYCLOAK_REALM,
      clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
    // url:'http://159.89.7.6:8089/auth',
    // realm:'fishfarm-farmer',
    // clientId:'farmer'
};
// console.log(keycloakConfig)
const farmerkeycloak = new Keycloak(keycloakConfig);

const initilizingKeycloakFarmer = () =>{
  const token = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('vueRefreshToken');
    
    farmerkeycloak
  .init({ onLoad: 'login-required', promiseType: 'native',token, refreshToken })
  .then((authenticated) => {
    if (authenticated) {
      console.log('User is authenticated');
      localStorage.setItem('accessToken', farmerkeycloak.token);
      localStorage.setItem('vueRefreshToken', farmerkeycloak.refreshToken);
      localStorage.setItem('idToken', farmerkeycloak.idToken)
    //   setAuthHeader(localStorage.accessToken)
      const authStore=useAuthStore();
      const user_name = farmerkeycloak.tokenParsed.name;
      const user_email = farmerkeycloak.tokenParsed.email;
      
      authStore.setAuth(user_name,user_email,authenticated);
    } else {
      console.log('User is not authenticated');
    //   const authStore=useAuthStore();
    //   authStore.authentication = false;
      localStorage.setItem("user",'')
      localStorage.setItem('accessToken', farmerkeycloak.token);
    }
  })
  .catch((error) => {
    console.error('Keycloak initialization error:', error);
  });
}
export { initilizingKeycloakFarmer,farmerkeycloak}