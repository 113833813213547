<template>
<v-sheet style="width:95%; height: 100%;">
    <v-row class="mt-4 ">
        <h1 class="ml-3 heading_styles">Add New Business</h1>
    </v-row>
    <!-- {{ v$.$errors}}
    {{ business }} -->
    <v-row class="mt-4">
        <v-col cols="12" md="6">
            <v-text-field :class="v$.business.business_name.$error? 'border-danger':'' " label="Business Name" placeholder="Enter your Business Name" class="text-high-emphasis " density="compact" variant="outlined" required v-model="business.business_name"></v-text-field>
            <small v-if="v$.business.business_name.$error" class="text-red">
                Please enter the business name
            </small>
        </v-col>

        <v-col cols="12" md="6">
            <v-text-field label="Email Address" placeholder="Enter Your Business Email" class="text-high-emphasis " density="compact" variant="outlined" required v-model="business.email"></v-text-field>
            <small v-if="v$.business.email.$error" class="text-red">
                Please enter a correct email address
            </small>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="6">
            <v-text-field label="Contact Number" placeholder="Enter your Contact Number" class="text-high-emphasis " density="compact" variant="outlined" required v-model="business.contact_number"></v-text-field>
            <small v-if="v$.business.contact_number.$error" class="text-red">
                Contact Number should be <span class="text-green">+923XXXXXXXXX</span>
            </small>
        </v-col>
        <v-col cols="12" md="6">
            <v-text-field label="Whatsapp Number" placeholder="Enter Your Whatsapp Number" class="text-high-emphasis " density="compact" variant="outlined" required v-model="business.whatsapp_number"></v-text-field>
            <small v-if="v$.business.whatsapp_number.$error" class="text-red">
                Contact Number should be <span class="text-green">+923XXXXXXXXX</span>
            </small>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="6">
            <v-text-field label="Facebook Page" placeholder="Enter your Facebook Page" class="text-high-emphasis " density="compact" variant="outlined" required v-model="business.facebook_page_link"></v-text-field>
            <small v-if="v$.business.facebook_page_link.$error" class="text-red">
                Please enter a valid profile link
            </small>
        </v-col>
        <v-col cols="12" md="6">
            <v-text-field label="Instagram Page" placeholder="Enter Your Instagram Page" class="text-high-emphasis " density="compact" variant="outlined" required v-model="business.instagram_page_link"></v-text-field>
            <small v-if="v$.business.instagram_page_link.$error" class="text-red">
                Please enter a valid profile link
            </small>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="6">
            <v-text-field label="Website" placeholder="Enter Business Website" class="text-high-emphasis " density="compact" variant="outlined" required v-model="business.website"></v-text-field>
            <small v-if="v$.business.website.$error" class="text-red">
                Please enter a valid website link
            </small>
        </v-col>

        <v-col cols="12" md="6">
            <v-select :items="business_types" item-title="business_type" item-value="id" label="Business Type" class="text-high-emphasis " density="compact" variant="outlined" v-model="business.business_type_id"></v-select>
            <small v-if="v$.business.business_type_id.$error" class="text-red">
                Please select a business type
            </small>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="6">
            <v-select :items="status" item-title="status" item-value="value" label="Status" class="text-high-emphasis " density="compact" variant="outlined" v-model="business.status"></v-select>
            <small v-if="v$.business.status.$error" class="text-red">
                Please select a business status
            </small>
        </v-col>
        <v-col cols="12" md="6">
            <v-select :items="cities" item-title="name" item-value="id" label="Select City" class="text-high-emphasis " density="compact" variant="outlined" v-model="business.city_id"></v-select>
            <small v-if="v$.business.city_id.$error" class="text-red">
                Please select a city
            </small>
        </v-col>
    </v-row>
    <!-- <v-row>
        <v-col cols="12">
            <v-textarea label="Address" rows="1" placeholder="Enter Business Address" class="text-high-emphasis " density="compact" variant="outlined" :readonly="edit_button" required v-model="business.address" @input="fetchAddressSuggestions"></v-textarea>
        </v-col>
    </v-row> -->
    <v-row>
        <v-col cols="12">
            <h3>Choose Location On Map</h3>
        </v-col>
        <v-col cols="12">
            <v-autocomplete :items="items" id="autocomplete" no-filter auto-select-first class="flex-full-width" density="compact" item-props menu-icon="" placeholder="Enter your complete address" append-inner-icon="mdi-search-web" variant="outlined" v-model="complete_address.address" @input="fetchAddressSuggestions"></v-autocomplete>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <GMapMap :center="center" :zoom="7" map-type-id="terrain" style="width: 100%; height: 40dvh" @click="MapClick">
                <GMapMarker :position="position" />
            </GMapMap>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" class="text-end mb-2">
            <v-btn variant="outlined">
                CLOSE
            </v-btn>
            <v-btn color="red" variant="outlined" class="ml-3" @click="RegisterBusiness()">
                ADD BUSINESS
            </v-btn>
        </v-col>
    </v-row>
</v-sheet>
<v-overlay v-model="overlay" persistent class="text-center align-center justify-center">
    <v-progress-circular indeterminate :size="110" :width="15" color="red">
        <v-img class=" text-center ml-auto" src="../assets/pimg.jpeg" style="position: fixed;  width: 70%; height: 70%; border-radius: 100%;"></v-img>
    </v-progress-circular>
</v-overlay>
<v-snackbar v-model="snackbar" vertical color="green" location="top right">
    <p class="text-subtitle-1">{{ snackbar_message }}</p>
</v-snackbar>
</template>

<script>
import axiosInstance from '../interceptors';
const api = axiosInstance;

import {
    useVuelidate
} from '@vuelidate/core'
import {
    required,
    email,
    alpha,
    minLength,
    url
} from '@vuelidate/validators'

export default {
    emits: ["switch_screen", "setSnackbar", "setOverlay"],
    data: () => ({
        v$: useVuelidate(),
        overlay: true,
        snackbar_message: '',
        snackbar: false,
        items: [],
        position: {
            lat: 30.3753,
            lng: 69.3451
        },
        center: {
            lat: 30.3753,
            lng: 69.3451
        },
        business_types: [],
        cities: [],
        selected_city: '',
        selected_business_type: '',
        complete_address: {
            delivery_address_id: '',
            contact_number: '',
            selected_region: '',
            selected_city: null,
            address: '',
        },
        status: [{
                'status': 'Active',
                'value': true
            },
            {
                'status': 'In Active',
                'value': false
            }
        ],
        business: {
            business_name: '',
            email: '',
            website: '',
            status: null,
            city_id: null,
            address: '',
            contact_number: '',
            whatsapp_number: '',
            facebook_page_link: '',
            instagram_page_link: '',
            business_type_id: null
        },
    }),
    validations() {
        return {
            business: {
                business_name: {
                    required,
                    alpha,
                    minLength: minLength(5)
                },
                email: {
                    required,
                    email
                },
                website: {
                    isValidSite: value => !value || url
                },
                status: {
                    required
                },
                city_id: {
                    required
                },
                business_type_id: {
                    required
                },
                // address: {
                //     required
                // },
                contact_number: {
                    required,
                    isValidPhoneNumber: value => /^\+\d{12}$/.test(value)
                },
                whatsapp_number: {
                    isValidPhoneNumber: value => !value || /^\+\d{12}$/.test(value)
                },
                facebook_page_link: {
                    isValidLink: value => !value || /^(?:https?:\/\/)?(?:www\.)?(mbasic\.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w.-]*\/)*([\w.-]*)+$/.test(value)                },

                instagram_page_link: {
                    isValidLink: value => !value || /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/[A-Za-z0-9_.]+$/.test(value)
                }

            }
        }
    },
    mounted() {
        this.GetBusinessTypes();
        this.GetCities();
    },
    methods: {
        GetBusinessTypes() {

            api.get('/business/types').then((response) => {
                this.business_types = response.data;
                this.overlay = false;
            }).catch((error) => {
                console.log(error)
            });

        },
        async GetCities() {
            api.get('/cities').then((response) => {

                this.cities = response.data;
                this.overlay = false;
            }).catch((error) => {
                console.log(error)
            });

        },
        RegisterBusiness() {
            const new_business = {
                name: this.business.business_name,
                email: this.business.email,
                website: this.business.website,
                business_type_id: this.business.business_type_id,
                status: this.business.status,
                city_id: this.business.city_id,
                // address: 'this.business.address',
                address: 'this is dummy address ',
                contact_number: this.business.contact_number,
                whatsapp_number: this.business.whatsapp_number,
                facebook_page_link: this.business.facebook_page_link,
                instagram_page_link: this.business.instagram_page_link,
            }
            console.log(new_business)
            this.v$.$validate();
            if (!this.v$.$error) {
                api.post('/business', new_business).then((response) => {
                    console.log(response.data);
                    this.overlay = true;
                    this.snackbar_message = "New Business Registered Successfully";
                    this.snackbar = true;

                    setTimeout(() => {
                        this.$router.push("/farms");
                    }, 1000);

                }).catch((error) => {
                    console.log(error)
                });

            } else {
                console.log(this.v$.$errors)
            }

        },
        fetchAddressSuggestions() {
            let address = document.getElementById('autocomplete').value
            console.log(address)
            const apiKey = 'AIzaSyCXw4Dck1z5nk786nnE8Uw8fzr9axv6A_k';
            const input = address;
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${input}&key=${apiKey}`)
                .then(response => response.json())
                .then(data => {
                    if (data.results) {
                        this.items = data.results.map(result => result.formatted_address);
                        console.log(this.items)
                    }
                })
                .catch(error => {
                    console.error('Error fetching address suggestions:', error);
                });
        },
        MapClick(event) {
            this.position.lat = event.latLng.lat();
            this.position.lng = event.latLng.lng();
            console.log(this.position)
        },
        // switch_screen() {
        //     this.$emit('switch_screen', 'my_business');
        // },
        // invokeSnackbar() {

        //     this.$emit('setSnackbar', true, "Business added successfully");

        // },
        // setIsLoading(status) {
        //     this.$emit('setOverlay', status);
        // },
    }
}
</script>
