<template>
 
        <v-navigation-drawer   class="navcolor" style="height: 100%;">
        
            <v-img  src="../assets/logos/logo1.png" class=" mt-6 mb-4" height="8%"></v-img>
            <v-divider  color="white" thickness="3" length="70%" class="mx-auto"></v-divider>
            <v-list density="compact" nav color="white" class="list_center">
                <v-list-item v-for="item in nav_items" :key="item" :title="item.name" :value="item.value" :to="item.value" class="item_on_hover" :prepend-icon="item.icon">
                </v-list-item>
            </v-list>
            
            <template v-slot:append>
                <v-menu >
                <template v-slot:activator="{ props }">
                    <v-btn class="list_menu_color mb-6  mt-4" size="x-large">
                    <v-list-item  :title="authStore.user.userName" class="list_menu_color text-white " :subtitle="authStore.user.email"  v-bind="props" >
                        <template v-slot:prepend>
                        <v-avatar :color=randcolor>
                        <span class="text-h5 ">{{ user_name }}</span>
                        </v-avatar>
                    </template>
                    </v-list-item>
                    </v-btn>
                </template>
                    <v-list-item v-for="item in nav_links" :key="item" :value="item.value" class="list_menu_color text-white " @click="UserAction(item.value)">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item>
                </v-menu>
                
            </template>
        
        </v-navigation-drawer>
        

</template>
<script>
import {useAuthStore} from '@/store/authstore';
import {farmerkeycloak} from '@/plugins/keycloak';
export default{
    data:()=>({
        nav_items: [{
                name: 'Dashboard',
                value: '/',
                icon: 'mdi-view-dashboard-variant'
            },
            {
                name: 'Add New Business',
                value: '/add_business',
                icon: 'mdi-store-plus'
            },
            {
                name: 'Registered Farms',
                value: '/farms',
                icon: 'mdi-store'
            },
            {
                name: 'Products',
                value: '/products',
                icon: 'mdi-shape'
            },
            {
                name: 'Orders',
                value: '/orders',
                icon: 'mdi-bag-personal'
            },
            // {
            //     name: 'Contact Us',
            //     value: '/contact_us',
            //     icon: 'mdi-card-account-phone'
            // },
        ],
        nav_links: [
            {
                name: 'My Page',
                value: 'page'
            },
            {
                name: 'Logout',
                value: 'logout'
            }
        ],
        colors: [
            "red",
            "blue",
            "green",
            "indigo",
            "purple",
            "teal",
            "orange",
            "brown",
            "deep-orange",
            "blue-grey",
            "cyan"
      ],
    }),
    computed:{
        randcolor(){

            let currentColor = this.colors[Math.floor(Math.random() * this.colors.length)];

            return currentColor
        },
        authStore() {
             return useAuthStore();
            },
            user_name(){
            
           return this.authStore.user.userName.split(' ').map(name => name[0]).join('').toUpperCase();
        },
        
    },
    methods:{
        UserAction(value)
        {
            console.log(value)
            if(value === 'logout'){
                farmerkeycloak.logout();
            }
            else{
                console.log("In Profile")
            //    this.switchScreenWithRequest('1','my_products');
            }
        },
    }
}
</script>
<style>
.navcolor {
    background-color: #31363C !important;
}

.item_on_hover {
    color: #8B8B8B;
}

.item_on_hover:hover {
    color: white;
    background-color: #4D535B;
}

.list_center {
    position: absolute;
    top: 30%;
    width:100%
}

.list_menu_color {
    background-color: #464A4F;
}


.heading_styles{
    color:#31363C;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.hide-background-color.v-btn::after {
  background-color: transparent !important;
}
.v-btn--size-x-large {
    --v-btn-size: 1.125rem;
    --v-btn-height: 67px;
    font-size: var(--v-btn-size);
    min-width: 74px ;
    padding: 0 24px;
}
.v-input__details {
        display: none; /* Hide the content */
    }

.hover-color:hover{
    background-color: #D85E63;
    color: white;
}
.v-pagination__list>.v-pagination__item>.v-btn:focus{
    background: #FB585E !important;
    color: white !important;
}
/* .my-pagination>.v-pagination__list>li {
    background: #DDF6FF !important;
    color: black !important;
}

.my-pagination>.v-pagination__list>li>.v-btn:focus {
    background: #FB585E !important;
    color: white !important;

} */
</style>