<template>
<v-sheet style="width:95%; height: 100%;">
    <v-row class="mt-4 ">
        <h1 class="ml-3 heading_styles">My Orders</h1>
    </v-row>
    <v-row>
        <v-col cols="12" md="6">
            <v-row>
                <v-col cols="6">
                    <v-select :items="order_status" item-title="order_status" item-value="id" label=" Order Status" class="text-high-emphasis" variant="outlined" density="compact" v-model="search_order_status" clearable=""></v-select>

                </v-col>
                <v-col cols="6">
                    <v-select :items="user_business" item-title="name" item-value="id" label=" Business Name" class="text-high-emphasis" variant="outlined" density="compact" v-model="search_order_farm" clearable=""></v-select>

                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" md="6" class="text-end">
            <v-btn variant="outlined" prepend-icon="mdi-filter" :width="$vuetify.display.xs?'100%':'235px'" size="large" @click="GetBusinessSpecificOrders()">
                Apply Filter
            </v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-table height="90dvh" fixed-header>
                <thead>
                    <tr style="background-color: #102536; color: #b0b4bb;">
                        <td>ORDER NO</td>
                        <td>BUSINESS NAME</td>
                        <td>ORDER STATUS</td>
                        <td>CUSTOMER NAME</td>
                        <td>PHONE NO</td>
                        <td>TOTAL AMOUNT</td>
                        <td>DELIVERY ADDRESS</td>
                        <td>INSTRUCTIONS</td>
                        <td>ACTION</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="ord in order" :key="ord" class="style_table" @click="edit_order(ord)">
                        <td> {{ ord.order_number}}</td>
                        <td> {{ ord.name }} </td>
                        <td>
                            <v-chip class="ma-2" :color="getStatusColor(ord.order_status)" label>
                                {{ord.order_status}}
                            </v-chip>
                        </td>
                        <td> {{ ord.full_name }} </td>
                        <td> {{ ord.phone_number }}</td>
                        <td class="text-green"> Rs. {{ ord.total_amount }}</td>
                        <td> {{ ord.address }}</td>
                        <td> {{ ord.instructions }}</td>
                        <td>
                            <v-btn icon="mdi-table-edit" variant="text" color="green" class="ml-n2" @click="edit_order(ord)"> </v-btn>
                        </td>
                    </tr>

                    <tr v-if="response_message" class="text-center text-red text-h6">
                        <td colspan="10"> {{ response_message }}</td>
                    </tr>
                </tbody>
            </v-table>
            <v-pagination v-if="total_pages>1" class="ml-auto" density="compact" :length="total_pages" v-model="current_page" :total-visible="5"></v-pagination>
        </v-col>
    </v-row>
    <!-- <v-dialog v-model="order_dialog" transition="dialog-top-transition">
        <v-container>
            <v-card class="mx-auto mt-16 rounded-xl elevation-7" max-width="600">
                <v-card-title class="text-center text-h5 mt-4 font-weight-bold"> EDIT ORDER</v-card-title>
                <v-card-text>
                    <v-row class="px-2 mt-2">
                        <v-col cols="12" md="6">
                            <v-text-field label="Order Number" class="text-high-emphasis " variant="outlined" density="compact" readonly v-model="order_updated.order_number"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Business Name" class="text-high-emphasis " variant="outlined" density="compact" readonly v-model="order_updated.name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="px-2 mt-2">
                        <v-col cols="12" md="6">
                            <v-select :items="order_status" item-title="order_status" item-value="id" label="Order status" class="text-high-emphasis" variant="outlined" density="compact" v-model="selected_status"></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Customer Name" class="text-high-emphasis " variant="outlined" density="compact" readonly v-model="order_updated.full_name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="px-2 mt-2">
                        <v-col cols="12" md="6">
                            <v-text-field label="Phone Number" class="text-high-emphasis " variant="outlined" density="compact" readonly v-model="order_updated.phone_number"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Amount" class="text-high-emphasis" variant="outlined" density="compact" readonly v-model="order_updated.total_amount"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="px-2 mt-2">
                        <v-col cols="12" md="6">
                            <v-text-field label="Delivery Address" class="text-high-emphasis " variant="outlined" density="compact" readonly v-model="order_updated.address"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Instructions" class="text-high-emphasis " variant="outlined" density="compact" readonly v-model="order_updated.instructions"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3 px-2">
                        <v-col cols="12" md="6">
                            <v-btn variant="outlined" color="error" rounded="lg" width="100%" @click="order_dialog = false"> close </v-btn>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-btn variant="flat" color="error" rounded="lg" width="100%" @click="UpdateOrder()"> Update Order </v-btn>
                        </v-col>

                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>
    </v-dialog> -->
</v-sheet>
<v-overlay v-model="overlay" persistent class="text-center align-center justify-center">
    <v-progress-circular indeterminate :size="110" :width="15" color="red">
        <v-img class=" text-center ml-auto" src="../assets/pimg.jpeg" style="position: fixed;  width: 70%; height: 70%; border-radius: 100%;"></v-img>
    </v-progress-circular>
</v-overlay>
</template>

<script>
import axiosInstance from '../interceptors';
const api = axiosInstance;
export default {
    data: () => ({
        overlay: true,
        order_dialog: false,
        order_status: [],
        user_business: [],
        order: [],
        search_order_farm: null,
        search_order_status: null,
        order_updated: [],
        selected_status: null,
        total_pages: null,
        current_page: null,
        response_message: ''
    }),
    mounted() {
        this.GetBusinessSpecificOrders();
        this.GetOrderStatus();
        this.GetBusinessNames();
    },
    methods: {
        // setValueFromSubling(value) {
        //     console.log("in oderssssss", value)
        //     this.search_order_farm = value;
        //     this.GetBusinessSpecificOrders();

        // },
        // UpdateOrder() {
        //     const order_update = {

        //         order_status_id: this.selected_status
        //     }
        //     const order_id = this.order_updated.id;

        //     api.put('/order/tracking/' + order_id, order_update)
        //         .then(response => {
        //             console.log(response)
        //             this.order_updated.orderStatus = order_update.order_status_id;
        //             const pos = this.order.findIndex(item => item.id == response.data.order_id);
        //             const status = this.order_status.filter(item => item.id == response.data.order_status_id);
        //             this.order[pos].order_status = status[0].order_status;
                    // this.order_dialog = false;
                    // this.invokeSnackbar();
                    // this.snackbar_message = "Order Status Updated successfully"
                    // this.snackbar=true; 
                    // if(response.status == 200 || response.status == 202)
                    // {   this.overlay=false;
                    //     this.selected_status = ''
                    //     
                    // }
        //         })
        //         .catch(error => {
        //             console.error(error);
        //         });

        //     console.log("UpdateOrder", order_update, order_id)
        // },
        edit_order(item) {
            this.$router.push("/order/" + item.id)
            // console.log(item)
            // this.order_updated = item
            // this.selected_status = this.order_updated.status_id; 
            // this.order_dialog = true
            // this.$router.push("/order/"+item.id)

        },
        GetBusinessNames() {
            api.get('/user/businesses')
                .then((response) => {
                    console.log(response)
                    this.user_business = response.data;
                    this.overlay = false;
                    // this.setIsLoading(false);
                })
                .catch((error) => console.error(error));

        },
        GetBusinessSpecificOrders() {

            const params = {
                status_id: this.search_order_status,
                business_id: this.search_order_farm
            }

            console.log(params)
            api.get('/business/orders', {
                    params
                })
                .then((response) => {
                    console.log("asdadasdasdasdasd", response);
                    this.order = response.data;
                    this.total_pages = response.pages;
                    this.current_page = response.current_page;
                    this.response_message = response.message;
                    this.overlay = false;
                    // this.setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    this.response_message = error
                });
        },
        GetOrderStatus() {
            api.get('/order/statuses')
                .then((response) => {
                    this.order_status = response.data;
                    this.overlay = false;
                    // this.setIsLoading(false);
                })
                .catch((error) => console.error(error));
        },
        // setIsLoading(status) {
        //     this.$emit('setOverlay', status);
        // },
        getStatusColor(orderStatus) {

            switch (orderStatus) {
                case 'Dispatched':
                    return 'orange';
                case 'Delivered':
                    return 'success';
                case 'Confirmed':
                    return 'primary';
                case 'New':
                    return 'blue';
                case 'Cancelled':
                    return 'red';
                default:
                    return '';
            }
        },
        // invokeSnackbar() {

        //     this.$emit('setSnackbar', true, "Order status updated successfully");

        // }
    }
}
</script>

<style scoped>
.style_table {
    cursor: pointer;
}

.style_table:hover {
    background-color: rgb(240, 240, 240);
    /* color: white; */
}
</style>
